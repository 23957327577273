import { useGLTF } from '@react-three/drei'
import React from 'react'
import { useRef } from 'react'
import { useFrame } from '@react-three/fiber'

import skyScene from '../assets/3d/sky.glb'

const Sky = ({isRotating}) => {

  const ref=useRef();
    const sky=useGLTF(skyScene)

    useFrame((_,delta)=>{
        if(isRotating){
            ref.current.rotation.y += 0.2* delta
        }
    }
    )

  return (
   <mesh ref={ref}>
    <primitive object={sky.scene}  />
   </mesh>
  )
}

export default Sky
