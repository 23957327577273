import { meta, shopify, starbucks, tesla } from "../assets/images";
import {
    car,
    contact,
    css,
    estate,
    express,
    git,
    github,
    html,
    javascript,
    linkedin,
    mongodb,
    motion,
    mui,
    nextjs,
    nodejs,
    pricewise,
    react,
    redux,
    sass,
    snapgram,
    summiz,
    tailwindcss,
    threads,
    typescript,

} from "../assets/icons";

import Firebase from '../assets/icons/Firebase.svg';
import HTML from '../assets/icons/HTML5.svg'
import cpp from '../assets/icons/cpp.svg'
import postman from '../assets/icons/Postman.svg'
import psql from '../assets/icons/PostgresSQL.svg'
import graphql from '../assets/icons/GraphQL.svg'
import docker from '../assets/icons/Docker.svg'
import socket from '../assets/icons/Socket.io.svg'
import nuvera from '../assets/icons/nuvera.webp'
import atomic from '../assets/icons/atomic.jpeg'
import freeLance from '../assets/icons/freelamnce.jpg'
import hubx from '../assets/icons/hubx.jpeg'
export const skills = [
    {
        imageUrl: css,
        name: "CSS",
        type: "Frontend",
    },
    {
        imageUrl: HTML,
        name: "HTML",
        type: "Frontend",
    },
   
    {
        imageUrl: express,
        name: "Express",
        type: "Backend",
    },
    {
        imageUrl: git,
        name: "Git",
        type: "Version Control",
    },
    {
        imageUrl: github,
        name: "GitHub",
        type: "Version Control",
    },
  
    {
        imageUrl: javascript,
        name: "JavaScript",
        type: "Frontend",
    },
    {
        imageUrl: mongodb,
        name: "MongoDB",
        type: "Database",
    },
    {
        imageUrl: motion,
        name: "Motion",
        type: "Animation",
    },

    {
        imageUrl: nextjs,
        name: "Next.js",
        type: "Frontend",
    },
    {
        imageUrl: nodejs,
        name: "Node.js",
        type: "Backend",
    },
    {
        imageUrl: react,
        name: "React",
        type: "Frontend",
    },
    {
        imageUrl: redux,
        name: "Redux",
        type: "State Management",
    },
    {
        imageUrl: tailwindcss,
        name: "Tailwind CSS",
        type: "Frontend",
    },
    {
        imageUrl: typescript,
        name: "TypeScript",
        type: "Frontend",
    },
    {
        imageUrl: Firebase,
        name: "Firebase",
        type: "backend",
    },
    {
        imageUrl: cpp,
        name: "C++",
        type: "backend",
    },{
        imageUrl: postman,
        name: "Postman",
        type: "backend",
    },{
        imageUrl: psql,
        name: "PostgresSQL",
        type: "backend",
    },
    {
        imageUrl:graphql ,
        name: "GraphQL",
        type: "backend",
    },
    {
        imageUrl:docker ,
        name: "Docker",
        type: "backend",
    }, {
        imageUrl:socket ,
        name: "Sockert.io",
        type: "backend",
    },
    
   
];

export const experiences = [
    {
        title: "Full Stack Developer",
        company_name: "NUEVERA INFOTECH PVT LTD",
        icon: nuvera,
        iconBg: "#accbe1",
        date: "May 2023 - Sep 2023",
        points: [
            "Designing and developing scalable web applications using the MERN stack (MongoDB, Express.js, React.js, Node.js) and integrating AWS services for cloud-based functionalities.",
            "Creating visually appealing and responsive user interfaces with Tailwind CSS, Bootstrap, and Material-UI, ensuring a seamless user experience across different devices and browsers.",
            "Implementing dynamic and interactive features in web applications with Next.js and React.js, while optimizing performance and SEO through server-side rendering and static site generation.",
            "Building and managing server-side logic and APIs using Express.js and Node.js, while maintaining best practices in code quality and collaborating with teams to deliver high-quality software."
        ]
        
    },
    {
        title: "Full Stack Developer",
        company_name: "Hubx",
        icon: hubx,
        iconBg: "white",
        date: "Dec 2023 - May 2024",
        points:[
            "Led the development of scalable and maintainable web applications using a diverse tech stack, including Node.js, Express.js, React.js, MongoDB, Firebase, and Next.js.",
            "Designed and implemented robust RESTful APIs and integrated third-party services, such as the Google Maps API, to add location-based features and enhance functionality.",
            "Developed secure and efficient payment gateways, ensuring seamless transactions and an improved user experience.",
        ]
    
    },
    {
        title: "Full Stack Developer",
        company_name: "Atomic House",
        icon: atomic,
        iconBg: "white",
        date: "June 2024 - Present",
        points: [
            "Designed and developed an interactive web application as a Full Stack Developer Intern, utilizing React.js and Next.js to create a dynamic and responsive user interface.",
            "Enhanced user experience by implementing GraphQL for efficient data fetching, using Tanstack Query for caching and state management, and applying optimistic updates to provide a smoother and more responsive interface.",
            "Developed backend functionalities with Node.js and PostgreSQL, using Prisma for streamlined database interactions, ensuring reliable and efficient data management.",
        ]
        
        
    },
    {
        title: "Freelancer",
        company_name: "Self Employed",
        icon: freeLance,
        iconBg: "white",
        date: "Jan 2024 - Present",
        points: [
            "Researched and developed a task management system for an education firm, utilizing the MERN stack to create both frontend and backend components.",
            "Integrated real-time chat functionality using sockets and implemented Google Drive for file management, enhancing collaboration and data handling.",
            "Designed and maintained the web application, ensuring a responsive and user-friendly interface with cross-browser compatibility.",
            "Collaborated with clients to gather requirements, provided technical solutions, and delivered high-quality products tailored to their needs."
        ]
    }
    
    
];

export const socialLinks = [
    {
        name: 'Contact',
        iconUrl: contact,
        link: '/contact',
    },
    {
        name: 'GitHub',
        iconUrl: github,
        link: 'https://github.com/Sahilll15',
    },
    {
        name: 'LinkedIn',
        iconUrl: linkedin,
        link: 'https://www.linkedin.com/in/sahilchalke/',
    }
];

export const projects = [
    {
        iconUrl: pricewise,
        theme: 'btn-back-red',
        name: 'NoteShare',
        description: 'NoteShare facilitate the sharing of college notes among students. It provides a centralized space where students can upload their course-related materials, such as lecture notes, presentations, and study guides, making them easily accessible to their peers',
        link: 'https://github.com/Sahilll15/NOTESHARE',
    },
    {
        iconUrl: threads,
        theme: 'btn-back-green',
        name: 'Entrepreneurs Connect',
        description: ' Entrepreneurs Connect, your gateway to a thriving community of ambitious visionaries, innovators, and game-changers. Our platform is designed to empower entrepreneurs like you to connect, collaborate, and grow together, because success is best achieved through shared experiences and collective wisdom.',
        link: 'https://github.com/Sahilll15/EntrepreneursConnect',
    },
    {
        iconUrl: snapgram,
        theme: 'btn-back-blue',
        name: 'Anonymous social App',
        description: 'Anonymous social a social media app where u dont have to disclose ur identity. U can post anything without any fear of being judged. ',
        link: 'https://github.com/Sahilll15/Anonymous_Social_frontend',
    },
    {
        iconUrl: car,
        theme: 'btn-back-pink',
        name: 'Chat Application',
        description: 'Developed a real-time chat application that integrates WebSocket technology for instant messaging. Features include secure user authentication, real-time communication, and message persistence for seamless interactions.',
        link: 'https://github.com/Sahilll15/Chat_App'
    }
    ,
    {
        iconUrl: estate,
        theme: 'btn-back-black',
        name: 'Storytelling Flutter App',
        description: 'Created a storytelling app using Flutter, designed to deliver engaging and interactive narratives. The app features a user-friendly interface for exploring and contributing to various stories, with multimedia support to enhance the storytelling experience.',
        link: 'https://github.com/Sahilll15/StoryTelling_flutter'
    }
    ,
    {
        iconUrl: summiz,
        theme: 'btn-back-blue',
        name: 'URL Shortener Application',
        description: 'Developed a URL shortener application that converts long URLs into shorter, more manageable links. The app provides an easy-to-use interface for users to shorten, track, and manage their URLs, making it ideal for sharing and monitoring link performance.',
        link: 'https://github.com/Sahilll15/URL_SHORTNER'
    }
    
    
];