import React from 'react'
import { Link } from 'react-router-dom';
import arrow from '../assets/icons/arrow.svg'


const InfoBox=({text,link,btnText})=>(
    <div className='info-box'>
        <p className=' font-medium sm:text-xl text-center'>{text}</p>
        <Link className='neo-brutalism-white neo-btn ' to={link}>{btnText}
            <img 
            src={arrow}
            alt='arrow'
            className='w-6 h-6 ml-2'
            />
        </Link>
    </div>
)

const renderContent={
    1:(
        <h1 className='sm:text-xl sm:leading-snug text-center neo-brutalism-blue py-8 px-8 text-white mx-5'>
            Hello,This is <span className='font-bold'>Sahil Chalke</span>
            <br/>
            I am a Full Stack Developer
            <br/>

            <Link className='neo-brutalism-white neo-btn text-sm' >
            Swipe Right 
            
        </Link>
            
        </h1>
    ),
    2:(
        
        <InfoBox text='
            I am a final year student 
            pursuing my Bachelors in
            Information Technology from
            vidyavaridhi college of engineering and technology, Vasai'
            link='/about' btnText='Read More' />
    ),        
       
    3:(
        <InfoBox text='
        Built various personal as well as freelancing projects using MERN stack '
        link='/projects' btnText='Have a look at my projects' />
    ),
    4:(
        <InfoBox text='
        I am open to work on new projects and
        collaborate with new people'   
        
            link='/contact' btnText='Contact me' />
    ),
  
}




const HomeInfo = ({currentStage}) => {
  return renderContent[currentStage] || null;
}

export default HomeInfo
